export const genreOptions = [
  "Adventure",
  "Anthology",
  "Apocalyptic",
  "Art",
  "Autobiography",
  "Biography",
  "Business",
  "Children's",
  "Classic",
  "Comics",
  "Contemporary",
  "Cookbook",
  "Crime",
  "Cyberpunk",
  "Drama",
  "Dystopian",
  "Erotica",
  "Essay",
  "Experimental",
  "Fable",
  "Family Saga",
  "Fantasy",
  "Fantasy/Sci-Fi",
  "Fairy Tale",
  "Fiction",
  "Finance",
  "Graphic Novel",
  "Historical",
  "Historical Fiction",
  "Horror",
  "Humor",
  "Music",
  "Mystery",
  "Mythology",
  "Novella",
  "Paranormal",
  "Philosophy",
  "Political",
  "Poetry",
  "Religion",
  "Romance",
  "Romantic Comedy",
  "Satire",
  "Science",
  "Science Fiction",
  "Self-Help",
  "Short Story",
  "Sociology",
  "Sports",
  "Steampunk",
  "Supernatural",
  "Thriller",
  "Travel",
  "War",
  "Western",
  "Young Adult",
];

export const formatOptions = [
  "Paperback",
  "Hardcover",
  "E-Book",
  "Audiobook",
  "Braille",
];
